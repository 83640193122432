<template>
  <li
    class="list-item"
    :class="{'active': isTabActive(option[valueLabel]),'has-children': hasChildren()}"
  >
    <a
      v-if="!hasChildren()"
      href="#"
      @click.prevent="$emit('tab',option[valueLabel])"
    >
      <div
        v-if="option.icon"
        :id="'step-'+option[valueLabel]"
        role="tab"
        :tabindex="idx"
        class="wizard-icon-circle md d-flex align-items-center"
        :class="{'checked bg-primary text-white':isTabActive(option[valueLabel]),}"
      >
        <FeatherIcon
          class="wizard-icon"
          :icon="option.icon"
          :class="{'text-white':isTabActive(option[valueLabel]),}"
        />
      </div>
      <span
        class="stepTitle"

        :class="{'active text-primary':isTabActive(option[valueLabel])}"
      >
        {{ option[label] }}
      </span>
    </a>
    <template v-else>
      <p
        class="navigation-header text-left"
      >
        {{ option[label] }}
      </p>
      <ul

        class="list-unstyled ml-2"
      >
        <template v-for="(child,index) in option.children">
          <ms-tabs-list-item
            :key="index"
            :option="child"
            :label="label"
            :value-label="valueLabel"
            :idx="index"
            :active-tab="activeTab"
            @tab="$emit('tab',$event)"
          />

        </template>
      </ul>
    </template>
  </li>
</template>

<script>

export default {
  name: 'MsTabsListItem',
  props: {
    option: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: 'text',
    },
    valueLabel: {
      type: String,
      default: 'value',
    },
    idx: {
      type: Number,
      default: 0,
    },
    activeTab: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isChildActive: false,
    }
  },
  methods: {
    hasChildren() {
      return this.option?.children?.length ?? false
    },
    isTabActive(tab) {
      return this.activeTab === tab
    },
  },
}
</script>
