<template>
  <ms-tabs
    :options="options"
    initial-selected="db"
    @active="activeTab = $event"
  >
    <template v-for="option in options">
      <div

        v-if="activeTab === option.value"
        :key="option.value"
        class="wizard-tab-container"
      >
        <component :is="option.component" />
      </div>
    </template>
  </ms-tabs>
</template>

<script>

import MsTabs from '@/@bya3/components/utils/MsTabs'

export default {
  name: 'NotificationsSettings',
  components: {
    MsTabs,
    'db-notification': () => import('./NotificationsTable'),
    'email-notification': () => import('./components/TelegramNotifications'),
    'telegram-notification': () => import('./components/TelegramNotifications'),
  },
  data() {
    return {
      activeTab: 'db',
      options: [],
    }
  },
  mounted() {
    this.options = [
      { text: this.$t('notifications.db'), value: 'db', component: 'db-notification' },
      // { text: this.$t('notifications.email'), value: 'email', component: 'email-notification' },
      { text: this.$t('notifications.telegram'), value: 'telegram', component: 'telegram-notification' },
    ]
  },
}
</script>
