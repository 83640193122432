<template>
  <div class="steps-transparent mx-auto md vertical">
    <div class="tab-navigation">
      <b-row>
        <b-col md="2">
          <ul class="tab-nav list-unstyled">
            <ms-tabs-list-item
              v-for="(option,idx) in options"
              :key="option[valueLabel]"
              :option="option"
              :label="label"
              :value-label="valueLabel"
              :idx="idx"
              :is-tab-active="isTabActive(option[valueLabel])"
              :active-tab="activeTab"
              @tab="setTab($event)"
            />
          </ul>
        </b-col>
        <b-col md="10">
          <div class="wizard-tab-content">
            <slot />
          </div>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import MsTabsListItem from '@/@bya3/components/utils/MSTabsListItem'

export default {
  name: 'MsTabs',
  components: { MsTabsListItem, BRow, BCol },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'text',
    },
    valueLabel: {
      type: String,
      default: 'value',
    },
    initialSelected: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      tabs: [],
      activeTab: null,
    }
  },
  mounted() {
    setTimeout(() => {
      this.setTab(this.initialSelected ?? this.options[0][this.valueLabel] ?? null)
    }, 50)
  },
  methods: {
    isTabActive(tab) {
      return this.activeTab === tab
    },
    hasChildren(option) {
      return option?.children?.length ?? false
    },
    setTab(tab) {
      this.activeTab = tab
      this.$emit('active', tab)
    },
  },
}
</script>
